/* global _api */
import React, { Component } from "react";

class Footer extends Component {
    render() {
        return (
            <footer
                className={
                    "footer" +
                    (this.props.transparent !== undefined ? " footer-transparent" : "")
                }
            >
                <div
                    className={
                        "container" + (this.props.fluid !== undefined ? "-fluid" : "")
                    }
                >
                    <p className="copyright text-center">
                        &copy; {1900 + new Date().getYear()}{" "}
                        SUUS EXPERT Sp. z o. o. <span className="text-muted"> // {typeof _api === "undefined" ? "???" : _api.config.env}</span>
                    </p>
                </div>
            </footer>
        );
    }
}
export default Footer;
