/* global _api */
import React, { Component } from "react";
import Card from "../../../components/Card/Card.jsx";
import Button from "../../../components/CustomButton/CustomButton.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import {
    Grid,
    Row,
    Col,
    FormGroup,
    FormControl,
    ControlLabel,
    Thumbnail,
    Modal
} from "react-bootstrap";

class ExpertAdminDocuments extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            preview: null,
            askAbout: null,
            items: this.props.items || []
        };
    }

    componentDidMount() {
        this.getDepartments();
    }

    componentWillUnmount() {
        _api.abort();
    }

    getDepartments() {
        let self = this;

        let departmentGuid = null;
        if (!self.props.userHasRole('admin')) {
            departmentGuid = self.props.user.user.department.uuid;
        }

        _api.request(`/admin/general/departments`, {},
            (data) => {
                let items = [];
                let selected = null;
                for (let i = 0; i < data.length; i++) {
                    items.push({ "value": data[i].uuid, "label": data[i].name, "region": data[i].region });
                    if (departmentGuid && data[i].uuid === departmentGuid) {
                        selected = items[i];
                    }
                }

                if (self.props.userHasRole(['expertManager'])) {
                    items = items.filter((elem) => { return elem.region === selected.region });
                }

                if (items.length) {
                    if (!self.state.department) {
                        if (selected) {
                            self.setDepartment(selected, items);
                        } else {
                            self.setDepartment(items[0], items);
                        }
                    }
                }

            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    setDepartment(currentDepartment, allDepartments) {
        let self = this;
        let callback = () => {
            self.loadScanList();
        }
        if (allDepartments) {
            self.setState({ department: currentDepartment, departments: allDepartments }, callback);
        } else {
            self.setState({ department: currentDepartment }, callback);
        }

    }

    toDataURL(url, callback) {
        let xhr = new XMLHttpRequest();
        xhr.onload = function () {
            let reader = new FileReader();
            reader.onloadend = () => {
                callback(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }

    handleCodeChange = (newElement) => {
        const newItems = this.state.items.map((currentElement) => {
            if (newElement.fileName !== currentElement.fileName) return currentElement;
            return newElement;
        });
        this.setState({ items: newItems });
    };

    loadScanList() {
        let self = this;
        _api.request(`/admin/document/scan-list`, {
            "department": {
                "uuid": self.state.department.value
            }
        },
            (data) => {
                self.setState({ items: data });
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    handleItemChange = (idx, field) => evt => {
        const newItems = this.state.items.map((item, sidx) => {
            if (idx !== sidx) return item;
            let updatedItem = { ...item };
            updatedItem[field] = evt.target.value;
            return updatedItem;
        });
        this.setState({ items: newItems });
    };

    showModal(item) {
        this.setState({ showModal: true, preview: item.url });
    }

    hideModal() {
        this.setState({ showModal: false, preview: null });
    }

    updateItem(item, action) {
        let self = this;
        if (action !== "remove") {
            if (!item.orderIdent) {
                self.props.showNoty('tr', 'warning', 'Wprowadź numer zlecenia');
                return;
            }
        }

        self.props.showNoty('tr', 'info', `Zapisywanie danych..`);
        _api.request(`/admin/document/scan-update/${action}`,
            {
                "uuid": item.uuid,
                "fileName": item.fileName,
                "orderIdent": item.orderIdent,
                "department": {
                    "uuid": self.state.department.value
                }
            },
            (data) => {
                self.setState({ items: this.state.items.filter((iteratedItem) => iteratedItem !== item) })
                self.props.showNoty('tr', 'success', `Zlecenie zostało przypisane`);
            },
            (error) => {
                switch (error) {
                    case 'E_ORDER_NOT_FOUND':
                        self.props.showNoty('tr', 'error', 'Zlecenie o podanym numerze nie zostało znalezione');
                        break;

                    default:
                        self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
                        break;
                }
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    removeItem(item) {
        this.setState({
            askAbout: (
                <SweetAlert
                    warning
                    showCancel
                    style={{ display: "block", marginTop: "-300px" }}
                    title="Czy chcesz usunąć ten skan?"
                    confirmBtnText="Usuń"
                    cancelBtnText="Anuluj"
                    onConfirm={() => { this.setState({ askAbout: null }); this.updateItem(item, 'remove'); }}
                    onCancel={() => { this.setState({ askAbout: null }); }}
                    confirmBtnBsStyle="warning">
                </SweetAlert>
            )
        });
    }

    produceThumbnail(item, idx) {
        return (
            <Col key={idx} xs={6} md={3}>
                <Thumbnail src={item.url}>
                    <div className="row">
                        <div className="col-md-12">
                            <FormGroup>
                                <ControlLabel>Numer zlecenia:</ControlLabel>
                                <FormControl
                                    type="text"
                                    placeholder={`...`}
                                    value={item.orderIdent || ""}
                                    onChange={this.handleItemChange(idx, 'orderIdent')}
                                />
                            </FormGroup>
                        </div>
                        <div className="col-md-12">
                            <FormGroup>
                                <Button style={{ margin: "0px 5px 0 5px" }} fill icon onClick={() => { this.updateItem(item, "update") }}>
                                    <i className="fa fa-save" />
                                </Button>
                                <Button style={{ margin: "0px 5px 0 5px" }} fill icon onClick={() => { this.removeItem(item) }} bsStyle="warning">
                                    <i className="fa fa-times" />
                                </Button>
                                <Button style={{ margin: "0px 5px 0 5px" }} fill icon onClick={() => { this.showModal(item) }} bsStyle="info">
                                    <i className="fa fa-search-plus" />
                                </Button>
                            </FormGroup>
                        </div>
                    </div>
                </Thumbnail>
            </Col>
        );
    }

    render() {

        const modal = (
            <Modal show={this.state.showModal} onHide={this.hideModal.bind(this)} bsSize="large" style={{ paddingLeft: 0 }} backdrop="static">
                <Modal.Header closeButton>Podgląd skanu</Modal.Header>
                <Modal.Body>
                    <Thumbnail src={this.state.preview}>
                    </Thumbnail>
                </Modal.Body>
            </Modal>
        )

        const placeholder = (
            <Col xs={12}>
                <p className="lead text-muted" style={{ border: `1px dashed #e3e3d3`, textAlign: `center`, padding: `8px`, lineHeight: `48px`, margin: `-10px -10px -10px -12px` }}>brak danych</p>
            </Col>
        )

        return (
            <div className="main-content">
                {modal}
                {this.state.askAbout}
                <Grid fluid>

                    <Row>
                        <Col sm={12}>
                            <Card
                                title="Wybór oddziału"
                                content={
                                    <div>
                                        <fieldset>
                                            <FormGroup>
                                                <Col style={{ "paddingTop": "10px" }}>
                                                    <Select
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        name="payer"
                                                        value={this.state.department}
                                                        placeholder="Wybierz oddział"
                                                        onChange={value =>
                                                            this.setDepartment(value)
                                                        }
                                                        options={this.state.departments}
                                                        isDisabled={!this.props.userHasRole(['admin', 'expertManager'])}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </fieldset>
                                    </div>
                                }
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12}>
                            <Card
                                content={
                                    <Row>
                                        {
                                            this.state.items
                                                ?
                                                this.state.items.map((item, idx) => {
                                                    return this.produceThumbnail(item, idx);
                                                })
                                                :
                                                placeholder
                                        }
                                    </Row>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

export default ExpertAdminDocuments;
