import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import NotificationSystem from "react-notification-system";
import { style } from "../variables/Variables.jsx";
import Footer from "../components/Footer/Footer.jsx";
import routes from "../routes.js";

class Auth extends Component {

    constructor(props) {
        super(props);
        this.state = {
            _notificationSystem: null
        };
    }

    componentDidMount() {
        this.setState({ _notificationSystem: this.refs.notificationSystem });
    }

    componentWillMount() {
        if (document.documentElement.className.indexOf("nav-open") !== -1) {
            document.documentElement.classList.toggle("nav-open");
        }
    }

    getPageClass() {
        var pageClass = "";
        switch (this.props.location.pathname) {
            case "/auth/login":
                pageClass = " login-page";
                break;
            default:
                pageClass = "";
                break;
        }
        return pageClass;
    }

    showNoty = (position, level, content) => {
        this.state._notificationSystem.addNotification({
            title: <span data-notify="icon" className="pe-7s-info" />,
            message: (<div>{content}</div>),
            level: level,
            position: position,
            autoDismiss: 5
        });
    };

    getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.collapse) {
                return this.getRoutes(prop.views);
            }
            if (prop.layout === "/auth") {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        key={key}
                        render={routeProps => (
                            <prop.component
                                {...routeProps}
                                showNoty={this.showNoty}
                            />
                        )}
                    />
                );
            } else {
                return null;
            }
        });
    };

    render() {
        return (
            <div>
                <div className="wrapper wrapper-full-page">
                    <NotificationSystem ref="notificationSystem" style={style} />
                    <div className={"full-page" + this.getPageClass()} data-color="light-blue">
                        <div className="content">
                            <Switch>{this.getRoutes(routes)}</Switch>
                        </div>
                        <Footer transparent />
                    </div>
                </div>
            </div>
        );
    }

}

export default Auth;
