/* global _api */
import React, { Component } from "react";
import {
    Grid,
    Row,
    Col,
    FormGroup,
    ControlLabel,
    FormControl
} from "react-bootstrap";
import Card from "../../../components/Card/Card.jsx";
import Button from "../../../components/CustomButton/CustomButton.jsx";
import image from "../../../assets/img/expert/logo-expert-white-background.png";

class ExpertAuthLogin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cardHidden: true,
            isLoading: false,
            showOtp: false,
            emailAddress: "",
            password: "",
            otp: ""
        };
    }

    componentDidMount() {
        setTimeout(
            function () {
                this.setState({ cardHidden: false });
            }.bind(this),
            700
        );
    }

    handleSubmit(event) {
        event.preventDefault();

        let self = this;
        self.setState({ isLoading: true });
        _api.request("/user/login",
            {
                "emailAddress": self.state.emailAddress,
                "password": self.state.password,
                "otp": self.state.otp,
            },
            (data) => {
                self.setState({
                    emailAddress: "",
                    password: "",
                    otp: "",
                    showOtp: false
                }, () => {
                    _api.sessionStore(data.token.uuid);
                    self.props.history.push('/profile/dashboard');
                })
            },
            (error) => {
                self.setState({ isLoading: false });
                if (error === 'E_USER_OTP_REQUIRED') {
                    if (self.state.showOtp) {
                        self.props.showNoty('tr', 'error', 'Błędny kod jednorazowy');
                    }
                    self.setState({ showOtp: true });
                } else {
                    self.props.showNoty('tr', 'error', 'Błędna nazwa użytkownika lub hasło');
                }
            }
        );
    }

    render() {
        return (
            <Grid>
                <Row>
                    <Col md={4} sm={6} mdOffset={4} smOffset={3}>
                        <form onSubmit={this.handleSubmit.bind(this)}>
                            <Card
                                hidden={this.state.cardHidden}
                                textCenter
                                image={image}
                                content={
                                    <div style={{ maxWidth: "80%", margin: "0 auto" }}>
                                        <FormGroup style={{ marginTop: 20 }}>
                                            <ControlLabel>Adres e-mail: </ControlLabel>
                                            <FormControl
                                                placeholder="Wprowadź e-mail"
                                                value={this.state.emailAddress}
                                                disabled={this.state.showOtp}
                                                type="email"
                                                name="email"
                                                autoComplete="username"
                                                onChange={event => {
                                                    this.setState({ emailAddress: event.target.value });
                                                }} />
                                        </FormGroup>
                                        <FormGroup>
                                            <ControlLabel>Hasło: </ControlLabel>
                                            <FormControl
                                                placeholder="Wprowadź hasło"
                                                value={this.state.password}
                                                disabled={this.state.showOtp}
                                                type="password"
                                                name="password"
                                                autoComplete="current-password"
                                                onChange={event => {
                                                    this.setState({ password: event.target.value });
                                                }} />
                                        </FormGroup>
                                        {
                                            this.state.showOtp && (
                                                <FormGroup>
                                                    <ControlLabel>Kod jednorazowy: </ControlLabel>
                                                    <FormControl
                                                        placeholder="Wprowadź kod"
                                                        value={this.state.otp}
                                                        type="text"
                                                        name="one-time-code"
                                                        autoComplete="one-time-code"
                                                        onChange={event => {
                                                            this.setState({ otp: event.target.value });
                                                        }} />
                                                </FormGroup>
                                            )
                                        }
                                    </div>
                                }
                                legend={
                                    <div style={{ maxWidth: "80%", margin: "0 auto" }}>
                                        <Button type="submit" disabled={this.state.isLoading} bsStyle="default" fill block onClick={!this.state.isLoading ? this.handleSubmit.bind(this) : null}>
                                            {this.state.isLoading ? 'Logowanie…' : 'Zaloguj się'}
                                        </Button>
                                    </div>
                                }
                                ftTextCenter
                            />
                        </form>
                    </Col>
                </Row>
            </Grid>
        );
    }
}

export default ExpertAuthLogin;
