/* global _api */
import React, { Component } from "react";
import Card from "../Card/Card.jsx";
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import PropagateLoader from 'react-spinners/PropagateLoader';
import TrackerMap from '../Map/TrackerMap.jsx';
import 'react-vertical-timeline-component/style.min.css';
import moment from "moment";
import "moment/min/locales";
import image from "../../assets/img/expert/logo-expert-dark-2.svg";
import { Badge, Row, Col, Button, FormControl, FormGroup, ControlLabel } from "react-bootstrap";
import { $STATUS2_DOSTARCZONE, $STATUS2_NIEZREALIZOWANE, $STATUS2_WDOSTAWIE, $STATUS2_ZGLOSZONOREKLAMACJE } from "../../constants.js";

moment.locale('pl');


class ExpertTrackerComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isConfirmLoading: false,
            orderTrash: false,
            orderConfirmed: false,
            orderTracker: "",
            orderId: "",
            orderIdent: "",
            orderStatus: "loading",
            orderLocation: null,
            orderCommentFleet: "",
            orderUuid: "",
            opinionFormLink: "",
            orderFiles: [],
            orderJustCreated: false,
            orderFinished: false,
            orderDriverAssigned: false,
            orderDriverFirstname: null,
            orderDriverLastname: null,
            orderDriverMobilePhone: null,
            csat: null,
            csatCustomerService: null,
            companyName: null,
            companyIndustry: null,
            receiptSaved: false,
            confirmComment: "",
            numberOfPackages: "",
            withDetails: this.props.withDetails || false,
            steps: []
        };
        this.tOutData = undefined;
    }

    componentDidMount() {
        let self = this;
        let orderTracker = self.props.orderTracker || null;
        if (!orderTracker) {
            self.setState({ orderStatus: "notfound" });
            return;
        }
        self.setState({ orderStatus: "loading", isLoading: true, orderTracker: orderTracker },
            () => {
                self.getCurrentData();
            }
        );
    }

    componentWillUnmount() {
        _api.abort();
        if (this.tOutData) {
            clearTimeout(this.tOutData);
        }
    }

    getCurrentData() {
        let self = this;
        _api.request(`/tracking/status/${self.state.orderTracker}`,
            {
                withDetails: self.state.withDetails
            },
            (data) => {
                if (data.order && data.order.status) {
                    let remoteOrderStatus = data.order.status.reverse().filter(itm => !this.props.hideStatus.includes(itm.name));
                    let orderFinished = false;
                    let orderJustCreated = false;

                    let newSteps = [];
                    remoteOrderStatus.forEach(remoteOrderStatus => {
                        newSteps.push({
                            color: '#007bff',
                            activeColor: '#87cb16',
                            currentColor: '#007bff',
                            icon: 'fa fa-cube',
                            h1: remoteOrderStatus.name,
                            h2: moment(remoteOrderStatus.created.date).format("YYYY-MM-DD HH:mm:ss"),
                            isActive: false,
                            isVisible: true,
                            isNewest: false,
                            withMap: [$STATUS2_WDOSTAWIE].includes(remoteOrderStatus.name),
                            created: false
                        })
                    })

                    if (newSteps.length) {
                        newSteps[newSteps.length - 1].isActive = true;
                        newSteps[newSteps.length - 1].isNewest = true;
                        newSteps[newSteps.length - 1].color = newSteps[newSteps.length - 1].activeColor;
                        orderFinished = [$STATUS2_DOSTARCZONE, $STATUS2_NIEZREALIZOWANE, $STATUS2_ZGLOSZONOREKLAMACJE].includes(newSteps[newSteps.length - 1].h1);
                    }

                    self.setState({
                        steps: newSteps,
                        orderStatus: "found",
                        orderTrash: data.order.trash || false,
                        orderTracker: data.order.tracker,
                        orderIdent: data.order.ident,
                        orderId: data.order.id,
                        orderLocation: data.order.location,
                        orderCommentFleet: data.order.commentFleet,
                        orderUuid: data.order.uuid,
                        opinionFormLink: data.order.opinionFormLink,
                        orderFiles: data.order.file || [],
                        orderFinished: orderFinished,
                        orderJustCreated: orderJustCreated,
                        csat: data.order.csat || null,
                        csatCustomerService: data.order.csatCustomerService || null,
                        companyName: data.company.name,
                        companyIndustry: data.company.industry,
                        orderConfirmed: data.order.confirmation.length ? true : false,
                        orderDriverAssigned: data.driver.assigned,
                        orderDriverFirstname: data.driver.firstname,
                        orderDriverLastname: data.driver.lastname,
                        orderDriverMobilePhone: data.driver.mobilePhone
                    });
                } else {
                    self.setState({ orderStatus: "found" });
                }
                self.tOutData = setTimeout(() => { self.getCurrentData() }, 5 * 1000);
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
                self.setState({ orderStatus: "notfound" });
            }
        );
    }

    handleCsat(csatObject) {
        let self = this;
        self.setState(csatObject);
        self.props.showNoty('tr', 'info', 'Zapisywanie danych..');
        _api.request(`/tracking/csat/${self.state.orderTracker}`, csatObject,
            (data) => {
                self.props.showNoty('tr', 'success', 'Dziękujemy za opinię!');
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas zapisywania danych');
            }
        );
    }

    handleReceiptSubmit(event) {
        event.preventDefault();
        let self = this;

        let numberOfPackages = parseInt(self.state.numberOfPackages, 10);
        if (Number.isNaN(numberOfPackages)) {
            self.props.showNoty('tr', 'error', 'Proszę podać poprawną liczbę paczek');
            return;
        }

        self.props.showNoty('tr', 'info', 'Zapisywanie danych..');
        self.setState({ isConfirmLoading: true });
        _api.request(`/tracking/confirmation/${self.state.orderTracker}`,
            {
                "comment": self.state.confirmComment,
                "numberOfPackages": numberOfPackages
            },
            (data) => {
                self.props.showNoty('tr', 'success', 'Dziękujemy za potwierdzenie!');
                self.setState({ isConfirmLoading: false, orderConfirmed: true });
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas zapisywania danych');
                self.setState({ isConfirmLoading: false });
            }
        );

    }

    render() {

        const orderLoading = (
            <div style={{ "paddingBottom": "26px" }}>
                <PropagateLoader
                    sizeUnit={"px"}
                    size={15}
                    margin={2}
                    css={{
                        "display": "block",
                        "margin": "10px auto",
                        "width": "1px"
                    }}
                    color={'#777'}
                    loading={true}
                />
            </div>
        );

        const csat =
            (this.props.showPoll && this.state.orderFinished) ?
                (
                    <VerticalTimelineElement
                        key="user-poll"
                        contentStyle={{ border: '1px solid #87cb16', boxShadow: 'none', color: '#87cb16' }}
                        contentArrowStyle={{ borderRight: '7px solid #87cb16' }}
                        iconStyle={{ background: '#87cb16', color: '#fff', boxShadow: 'none' }}
                        icon={<div style={{ textAlign: "center" }}><i style={{ fontSize: "20px", lineHeight: "40px", width: "40px" }} className="fa fa-question"></i></div>}
                    >
                        <h4 className="vertical-timeline-element-title">Ocena satysfakcji</h4>
                        <h5 className="vertical-timeline-element-subtitle">Prosimy o ocenę wykonanej usługi</h5>
                        <div style={{ marginTop: "20px", textAlign: "center" }}>
                            <a href={this.state.opinionFormLink ? this.state.opinionFormLink : `https://expert.suus.com/a?id=${this.state.orderId}&tracker=${this.state.orderTracker}`} className="btn btn-success btn-block" target="_blank" rel="noopener noreferrer">Przejdź do ankiety</a>
                        </div>
                    </VerticalTimelineElement>
                )
                : (<></>);

        const receipt =
            (this.props.showForm && this.state.orderFinished && !this.state.orderConfirmed) ?
                (
                    <VerticalTimelineElement
                        key="user-receipt"
                        contentStyle={{ border: '1px solid #333', boxShadow: 'none', color: '#333' }}
                        contentArrowStyle={{ borderRight: '7px solid #333' }}
                        iconStyle={{ background: '#333', color: '#fff', boxShadow: 'none' }}
                        icon={<div style={{ textAlign: "center" }}><i style={{ fontSize: "20px", lineHeight: "40px", width: "40px" }} className="fa fa-question"></i></div>}
                    >
                        <h4 className="vertical-timeline-element-title">Potwierdzenie dostawy</h4>
                        <h5 className="vertical-timeline-element-subtitle">Prosimy o samodzielne potwierdzenie dostawy towaru.</h5>
                        <form onSubmit={this.handleReceiptSubmit.bind(this)}>
                            <FormGroup style={{ marginTop: "20px" }}>
                                <ControlLabel style={{ fontSize: "16px", textTransform: "none" }}>Potwierdzam odbiór następującej liczby paczek do zamówienia <strong>{this.state.orderIdent}</strong>:</ControlLabel>
                                <FormControl
                                    type="text"
                                    placeholder="(wprowadź liczbę paczek)"
                                    value={this.state.numberOfPackages}
                                    onChange={event =>
                                        this.setState({ numberOfPackages: event.target.value })
                                    }
                                />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel style={{ fontSize: "16px", textTransform: "none" }}>Uwagi dodatkowe:</ControlLabel>
                                <FormControl
                                    type="text"
                                    placeholder="(opcjonalnie)"
                                    value={this.state.confirmComment}
                                    onChange={event =>
                                        this.setState({
                                            "confirmComment": event.target.value
                                        })
                                    }
                                />
                            </FormGroup>
                            <FormGroup>
                                <Button type="submit" style={{ width: "100%" }} disabled={this.state.isConfirmLoading} bsStyle="default" onClick={!this.state.isConfirmLoading ? this.handleReceiptSubmit.bind(this) : null}>
                                    {this.state.isConfirmLoading ? 'Zapisywanie…' : 'Potwierdzam odbiór'}
                                </Button>
                            </FormGroup>
                            <p style={{ textAlign: "center", fontSize: "12px" }}>&copy; Touch Free Technology<br />by Remowings</p>
                        </form>
                    </VerticalTimelineElement>
                )
                : (this.state.orderFinished && this.state.orderConfirmed) ?
                    (
                        <VerticalTimelineElement
                            key="user-receipt"
                            contentStyle={{ border: '1px solid #87cb16', boxShadow: 'none', color: '#87cb16' }}
                            contentArrowStyle={{ borderRight: '7px solid #87cb16' }}
                            iconStyle={{ background: '#87cb16', color: '#fff', boxShadow: 'none' }}
                            icon={<div style={{ textAlign: "center" }}><i style={{ fontSize: "20px", lineHeight: "40px", width: "40px" }} className="fa fa-question"></i></div>}
                        >
                            <h4 className="vertical-timeline-element-title">POTWIERDZENIE DOSTAWY</h4>
                            <h5 className="vertical-timeline-element-subtitle">Dostawa została potwierdzona w formie deklaracji cyfrowej.</h5>
                        </VerticalTimelineElement>
                    )
                    : (<></>);

        let orderDetails = (
            <div>
                <p>Śledzenie zamówienia numer <strong>{this.state.orderIdent} {this.state.orderTrash ? <Badge bsStyle="danger">anulowane</Badge> : <></>}</strong> - {this.state.companyName}.</p>
                <p>
                    {
                        this.state.orderDriverAssigned ?
                            (
                                <>
                                    Kierowca realizujący zlecenie: {this.state.orderDriverFirstname} {this.state.orderDriverLastname}, telefon:
                                    &nbsp;
                                    <a href={`tel:${this.state.orderDriverMobilePhone}`}>{this.state.orderDriverMobilePhone}</a>
                                </>
                            )
                            :
                            (
                                "Nie przypisaliśmy jeszcze kierowcy do tego zlecenia."
                            )
                    }
                </p>
                <VerticalTimeline
                    layout="1-column">
                    {
                        this.state.steps.map((step, idx) => {
                            return (
                                step.isVisible ? (
                                    <VerticalTimelineElement
                                        key={idx}
                                        contentStyle={{ border: '1px solid ' + step.color, boxShadow: 'none', color: step.color }}
                                        contentArrowStyle={{ borderRight: '7px solid ' + step.color }}
                                        iconStyle={{ background: step.color, color: '#fff', boxShadow: 'none' }}
                                        icon={<div style={{ textAlign: "center" }}><i style={{ fontSize: "20px", lineHeight: "40px", width: "40px" }} className={step.icon}></i></div>}
                                    >
                                        <h4 className="vertical-timeline-element-title">{step.h1}</h4>
                                        <h5 className="vertical-timeline-element-subtitle">{step.h2}</h5>
                                        {
                                            step.withMap && step.isActive && this.state.orderLocation && (
                                                <div style={{ marginTop: "10px" }}>
                                                    <TrackerMap
                                                        loadingElement={<div style={{ height: `100%` }} />}
                                                        containerElement={<div style={{ height: `280px` }} />}
                                                        mapElement={<div style={{ height: `100%` }} />}
                                                        defaultCenter={{ lat: parseFloat(this.state.orderLocation.lat), lng: parseFloat(this.state.orderLocation.lng) }}
                                                        markerPosition={{ lat: parseFloat(this.state.orderLocation.lat), lng: parseFloat(this.state.orderLocation.lng) }}
                                                    />
                                                    <p>Ostatnia lokalizacja: {moment(this.state.orderLocation.ts.date).fromNow()}</p>
                                                </div>

                                            )
                                        }
                                    </VerticalTimelineElement>
                                ) : <div key={idx}></div>
                            )
                        })
                    }
                    {csat}
                    {receipt}
                </VerticalTimeline>
            </div>
        );

        let orderNotFound = (
            <span>Zamównienie o podanym numerze nie zostało znalezione.</span>
        );

        let orderResponse = (<></>);

        let orderFleetDetails = (
            this.state.withDetails ? (
                <div>
                    <p><i>{this.state.orderCommentFleet || "Brak komentarzy dostawcy"}</i></p>
                    <p className="lead">Załączone pliki</p>
                    <ul style={{ marginBottom: "20px" }}>
                        {
                            this.state.orderFiles && this.state.orderFiles.length ?
                                this.state.orderFiles.map((prop, key) => {
                                    return (
                                        <li key={prop.uuid}>
                                            <a href={prop.url} target="_blank" rel="noopener noreferrer"><i className="fa fa-file" /> {prop.name}</a>
                                        </li>
                                    );
                                }) : <li><strong>BRAK</strong></li>
                        }
                        {
                            this.state.orderFiles && this.state.orderFiles.length ?
                                (
                                    <li>
                                        <p key={"file-all"}><a href={`${_api.config.url}link/zip/${this.state.orderUuid}?token=${_api.session.access_token}`} target="_blank" rel="noopener noreferrer"><i className="fa fa-file-archive-o" /> Pobierz wszystkie</a></p>
                                    </li>
                                ) : <></>
                        }
                    </ul>
                    <p className="lead">Historia statusów</p>
                </div>
            ) : <></>
        );

        switch (this.state.orderStatus) {
            case 'loading':
                orderResponse = (<>{orderLoading}</>);
                break;

            case 'notfound':
                orderResponse = (<>{orderNotFound}</>);
                break;

            case 'found':
                orderResponse = (<>{orderFleetDetails}{orderDetails}</>);
                break;

            default:
                orderResponse = (<></>);
                break;
        }

        let orderInContainer = (
            <>
                <Card
                    title={this.state.withDetails ? "Status zamówienia" : "Szczegóły zamówienia"}
                    content={
                        <div className="main-content">
                            {orderResponse}
                            <a href="https://expert.suus.com/" target="_blank" rel="noopener noreferrer" style={{ display: 'block', width: 150, margin: '20px auto' }}>
                                <img src={image} style={{ width: 150 }} />
                            </a>
                        </div>
                    }
                />
            </>
        )

        return orderInContainer;

    }

}

export default ExpertTrackerComponent;
